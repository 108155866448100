<template>
  <div class="password-rest d-flex justify-center">
    <div class="content-container d-flex flex-column">
      <div class="page-title text-center">Сброс пароля</div>

      <v-sheet class="mt-8 px-12 py-6">
        <v-form :disabled="isPending">
          <password-input
            v-model="formModel.new_password"
            autocomplete="new-password"
            label="Новый пароль"
            hide-details
          />
        </v-form>

        <v-btn
          class="mt-8 w-100"
          :disabled="!formModel.new_password"
          large
          outlined
          color="primary"
          @click="resetPassword()"
        >
          Задать новый пароль
        </v-btn>
      </v-sheet>
    </div>
  </div>
</template>

<script>
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'

  export default {
    name: 'ResetPasswordView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        formModel: {
          new_password: null
        }
      }
    },

    created() {
      if (!this.$route.query.reset_code) {
        this.$router.push('/')
      }
    },

    methods: {
      @handlePendingTask()
      async resetPassword() {
        await this.$api.backend.users.reset_password({
          reset_code: this.$route.query.reset_code,
          new_password: this.formModel.new_password
        })
        this.$router.push('/login')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .password-rest {
    .content-container {
      margin-top: 128px;
      margin-bottom: 32px;
      width: 520px;
    }
  }
</style>
